import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import './App.scss';
import SharePage from './pages/Home/SharePage';
import { Button, Modal } from "react-bootstrap";
import { TOKEN } from './lib/constant';
import { toast } from "react-toastify";

const App = () => {
  const [pwd, setPwd] = useState('')
  const localstorageData = localStorage.getItem('token')
  const saveHandler = () => {
    if (pwd === TOKEN) {
      localStorage.setItem('token', pwd)
      window.location.reload()
    } else {
      toast.warn('Please enter valid password.')
    }
  }
  if ((localstorageData !== TOKEN) || !localstorageData) {
    return <div className='auth-page' >
      <input
        type="password"
        name="name"
        className="pwd-input m-2"
        value={pwd}
        onChange={(e) => setPwd(e.target.value)}
        autoComplete="off"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            saveHandler()
          }
        }}
      />
      <Button
        className=""
        size="lg"
        style={{ backgroundColor: 'black', borderColor: 'black' }}
        type="submit"
        onClick={() => saveHandler()}>
        Enter Password
      </Button>
    </div>

  } else {
    return (
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home category="pillow" />} />
            <Route path="/pillow" element={<Home category="pillow" />} />
            <Route path="/upholstery" element={<Home category="upholstery" />} />
            {/* <Route path="/upholstery" element={<Home category="pillow" />} /> */}
            <Route path="/drapery" element={<Home category="drapery" />} />
            <Route path="/vintage" element={<Home category="vintage" />} />
            <Route path="/project/:id" element={<SharePage />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
