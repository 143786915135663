/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
// import "@google/model-viewer";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    addBandFinalFabricBeforeAtom,
    addToProjectAtom,
    addToProjectImageAtom,
    beforeSelectedImageScaletom,
    currentCustomizationAtom,
    currentPillowStateAtom,
    customBandValueAtom,
    fabricAtom,
    loaderAtom,
    modelAtom,
    offsetValueAtom,
    pillowSideAtom,
    preFixAtom,
    selectedAllBandAtom,
    selectedBandAtom,
    selectedImageScaletom,
    uploadImageAtom,
} from "../../state";
import { GET_URL } from "../../constans";
import { Calculator } from "../Calculator";
import { ImageGlobalUrl } from '../../lib/constant'
import { bandSellPriceCalulate, edgeSellPriceCalulate, edgeCostPriceCalulate, yardValue, bandCostPriceCalulate } from "../../lib/constant";

const textures = {};

const PillowModelViewer = () => {
    const [modelViewer, setModelViewer] = useState(document.querySelector("#model-viewer"))
    const fabric = useRecoilValue(fabricAtom);
    const uploadImage = useRecoilValue(uploadImageAtom)
    const inputValue = useRecoilValue(selectedImageScaletom);
    const inputSetValue = useRecoilValue(beforeSelectedImageScaletom);
    const currentPillowState = useRecoilValue(currentPillowStateAtom);
    const currentCustomization = useRecoilValue(currentCustomizationAtom);
    const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
    const preFix = useRecoilValue(preFixAtom);
    const [addToProject, setAddToProject] = useRecoilState(addToProjectAtom);
    const [addToProjectImage, setAddToProjectImage] = useRecoilState(addToProjectImageAtom);
    const getCheckSupport = localStorage.getItem('checkSupport')
    const offsetValue = useRecoilValue(offsetValueAtom)
    const pillowSide = useRecoilValue(pillowSideAtom);
    const selectedBand = useRecoilValue(selectedBandAtom)
    const selectedAllBand = useRecoilValue(selectedAllBandAtom)
    const addBandFinalFabricBefore = useRecoilValue(addBandFinalFabricBeforeAtom)
    const [customBandValue, setCustomBandValue] = useRecoilState(customBandValueAtom)
    const [loader, setLoader] = useRecoilState(loaderAtom)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setModelViewer(document.querySelector("#model-viewer"))
        }, 1000);
        setTimeout(() => {
            clearInterval(intervalId);
        }, 1000);
    }, []);

    useEffect(() => {
        updateMaterial();
    }, [fabric?.partWiseFabric, modelAtomValue, modelViewer, selectedBand, selectedAllBand, addBandFinalFabricBefore, customBandValue]);

    useEffect(() => {
        const { Front, Back } = uploadImage;
        if (Front || Back) {
            if (Front) {
                createTextureImages('Front', Front);
            }
            if (Back) {
                createTextureImages('Back', Back);
            }
        }
    }, [modelAtomValue, modelViewer, uploadImage]);

    // in-built fabric apply at that time apply uv model
    useEffect(() => {
        if (fabric?.partWiseFabric.Front || fabric?.partWiseFabric.Back) {
            (async () => {
                setTimeout(async () => {
                    await getUVModel()
                }, 1500)
            })()
        }
    }, [fabric?.partWiseFabric, modelAtomValue, modelViewer]);

    // custom fabric apply at that time apply uv model
    useEffect(() => {
        const { Front, Back } = uploadImage;
        if (Front || Back) {
            (async () => {
                setTimeout(async () => {
                    await getUVModel()
                    await getImageUVModel()
                }, 1500)
            })()
        }
    }, [modelViewer, uploadImage, inputValue]);

    const createTextureImages = (imageType, image) => {
        createAndApplyTextureImage('baseColorTexture', image, imageType);
        createAndApplyTextureImage('metallicRoughnessTexture', 'metalicRoughness.jpeg', imageType);
        createAndApplyTextureImage('normalTexture', 'normal.jpg', imageType);
    }

    const createAndApplyTextureImage = async (channel, path, keys) => {
        if (modelViewer?.model) {
            var texture = await modelViewer.createTexture(path);
            const model = modelViewer.model;
            const parts = model[Object.getOwnPropertySymbols(model)[1]];
            const meterialIndexies = [];
            const partWiseIndex = {};

            parts.forEach((part) => {
                const index = part.initialMaterialIdx;
                if (index || index == 0) {
                    partWiseIndex[index] = part.name;
                    if (keys?.includes(part.name)) {
                        meterialIndexies.push(index);
                    }
                }
            });

            meterialIndexies.forEach((index) => {
                const material = modelViewer.model.materials[index];
                if (channel.includes("base") || channel.includes("metallic")) {
                    material.pbrMetallicRoughness[channel].setTexture(null);
                    material.pbrMetallicRoughness[channel].setTexture(texture);
                } else {
                    material[channel].setTexture(null);
                    material[channel].setTexture(texture);
                }
            });
        }
    }

    const getImageUVModel = async () => {
        try {
            if (modelViewer?.model) {

                const model = modelViewer.model;
                let keys = Object.keys(uploadImage);

                if (!model || !keys?.length) return;

                const parts = model[Object.getOwnPropertySymbols(model)[1]];
                let meterialIndexies = [];

                for (const key of keys) {
                    // eslint-disable-next-line no-loop-func
                    parts.forEach((part) => {
                        if (key === part.name) {
                            const index = part.initialMaterialIdx;
                            meterialIndexies = {
                                ...meterialIndexies,
                                [key]: index,
                            };
                        }
                    });
                }
                for (const fabricKey of keys) {
                    if (typeof meterialIndexies[fabricKey] === 'number' && isFinite(meterialIndexies[fabricKey])) {
                        const checkImage = Object.keys(uploadImage);
                        const material = modelViewer.model.materials[meterialIndexies[fabricKey]];
                        const diffuse_map = material.pbrMetallicRoughness['baseColorTexture']?.texture?.sampler;
                        const normal_map = material?.normalTexture?.texture?.sampler;
                        // const metallic_map = material.pbrMetallicRoughness['metallicRoughnessTexture'].texture.sampler;

                        const checkScale = inputValue?.[fabricKey]?.Horizontal !== 0 && inputValue?.[fabricKey]?.Vertical !== 0

                        if (checkScale || !checkImage?.includes(fabricKey)) {
                            // set scale
                            const xValue = Number(!checkImage?.includes(fabricKey) ? fabric.partWiseFabric[fabricKey].repeatX : inputValue?.[fabricKey]?.Horizontal)
                            const yValue = Number(!checkImage?.includes(fabricKey) ? fabric.partWiseFabric[fabricKey].repeatY : inputValue?.[fabricKey]?.Vertical)
                            if (Number(yValue) || Number(xValue)) {
                                const scale = {
                                    u: xValue || 0,
                                    v: yValue || 0,
                                };
                                diffuse_map?.setScale(scale);
                                normal_map?.setScale(scale);
                            }
                            // metallic_map.setScale(scale);
                        }
                        if (checkImage?.includes(fabricKey) && offsetValue[fabricKey]) {
                            diffuse_map?.setOffset(offsetValue[fabricKey]);
                        }
                    }
                }
            }
        } catch (error) {
            console.log("🚀 ~ error file: PillowModelViewer.js:170 ~ getImageUVModel ~ error:", error)
        }
    };
    const getUVModel = async () => {
        try {
            if (modelViewer?.model) {

                const model = modelViewer.model;
                let keys = [...Object.keys(fabric.partWiseFabric), ...Object.keys(uploadImage)];

                if (!model || !keys?.length) return;
                const parts = model[Object.getOwnPropertySymbols(model)[1]];
                let meterialIndexies = [];

                for (const key of keys) {
                    // eslint-disable-next-line no-loop-func
                    parts.forEach((part) => {
                        if (key === part.name) {
                            // const index = part.initialMaterialIdx
                            const index = part.initialMaterialIdx;
                            meterialIndexies = {
                                ...meterialIndexies,
                                [key]: index,
                            };
                        }
                    });
                }

                for (const fabricKey of keys) {
                    if (typeof meterialIndexies[fabricKey] === 'number' && isFinite(meterialIndexies[fabricKey])) {
                        const checkImage = Object.keys(uploadImage);
                        const material = modelViewer.model.materials[meterialIndexies[fabricKey]];
                        const diffuse_map = material?.pbrMetallicRoughness['baseColorTexture']?.texture?.sampler;
                        const normal_map = material?.normalTexture?.texture?.sampler;
                        // const metallic_map = material.pbrMetallicRoughness['metallicRoughnessTexture'].texture.sampler;
                        const checkScale = inputValue?.[fabricKey]?.Horizontal !== 0 && inputValue?.[fabricKey]?.Vertical !== 0

                        if (checkScale || !checkImage?.includes(fabricKey)) {
                            // set scale
                            const xValue = Number(!checkImage?.includes(fabricKey) ? fabric.partWiseFabric[fabricKey].repeatX : inputValue?.[fabricKey]?.Horizontal)
                            const yValue = Number(!checkImage?.includes(fabricKey) ? fabric.partWiseFabric[fabricKey].repeatY : inputValue?.[fabricKey]?.Vertical)
                            if (Number(yValue) || Number(xValue)) {
                                const scale = {
                                    u: xValue || 0,
                                    v: yValue || 0,
                                };
                                diffuse_map?.setScale(scale);
                                normal_map?.setScale(scale);
                            }
                        }
                        if (checkImage?.includes(fabricKey) && offsetValue[fabricKey]) {
                            diffuse_map?.setOffset(offsetValue[fabricKey]);
                        }
                    }
                }
            }
        } catch (error) {
            console.log("🚀 ~ error file: PillowModelViewer.js:248 ~ getUVModel ~ error:", error)
        }
        setLoader(false)
    };

    const applyNormalTexure = () => {
        setTimeout(async () => {
            if (window.PIXI) {
                const app = new window.PIXI.Application({
                    width: window.innerWidth,
                    height: window.innerHeight,
                    backgroundColor: 0xAAAAAA,
                    view: document.getElementById('renderCanvas'),
                });
                const texture = window.PIXI.Texture.from(addBandFinalFabricBefore);
                const inputSprite = new window.PIXI.Sprite();
                const final_img = addBandFinalFabricBefore
                var image = new Image();
                image.src = final_img;
                await image.decode();
                const { height: imageHeight, width: imageWidth } = image;
                inputSprite.texture = texture;
                inputSprite.width = imageWidth;
                inputSprite.height = imageHeight;
                app.renderer.resize(imageWidth, imageHeight);

                const canvas = app.view;
                canvas.classList.add('d-none');
                document.body.appendChild(canvas);

                // Create a sprite to display the input image

                app.stage.addChild(inputSprite);

                // Load the fragment shader for normal map generation
                const fragmentShader = `
                precision mediump float;

                varying vec2 vTextureCoord;
                uniform sampler2D uSampler;
                uniform vec2 u_resolution;
                uniform float invertR;
                uniform float invertG;
                uniform float bias;

                void main() {
                    float pixelSize = 1.0 / u_resolution.x;
                    float dx = texture2D(uSampler, vTextureCoord + vec2(pixelSize, 0.0)).r - texture2D(uSampler, vTextureCoord - vec2(pixelSize, 0.0)).r;
                    float dy = texture2D(uSampler, vTextureCoord + vec2(0.0, pixelSize)).r - texture2D(uSampler, vTextureCoord - vec2(0.0, pixelSize)).r;

                    vec3 normal = normalize(vec3(dx * invertR, dy * invertG, 1.0 - (bias / 100.0)));
                    gl_FragColor = vec4(normal * 0.5 + 0.5, 1.0);
                }
          `;

                // Create a new window.PIXI.Filter using the custom fragment shader
                const normalMapFilter = new window.PIXI.Filter(null, fragmentShader);

                // Set the resolution uniform for the filter
                normalMapFilter.uniforms.u_resolution = new Float32Array([imageWidth, imageHeight]);
                normalMapFilter.uniforms.invertR = 1;
                normalMapFilter.uniforms.invertG = 1;
                normalMapFilter.uniforms.bias = 50;

                // Apply the filter to the input sprite
                inputSprite.filters = [normalMapFilter];

                // Temporary render texture for blur
                const renderTexture = PIXI.RenderTexture.create({
                    width: imageWidth,
                    height: imageHeight,
                });

                // Render sprite with blur filter applied
                const blurredSprite = new PIXI.Sprite(renderTexture);
                app.stage.addChild(blurredSprite);

                app.renderer.render(app.stage, undefined, true, null, false);

                const renderPromise = new Promise((resolve) => {
                    app.renderer.once('postrender', resolve);
                });
                renderPromise.then(() => {
                    // Get the rendered canvas
                    const canvas = app.renderer.view
                    const base64URL = canvas.toDataURL('image/jpeg', 0.8);

                    setTimeout(() => {
                        if (modelViewer?.model) {
                            const model = modelViewer.model;
                            const parts = model[Object.getOwnPropertySymbols(model)[1]];

                            const meterialIndexies = [];
                            const partWiseIndex = {};

                            parts.forEach((part) => {
                                const index = part.initialMaterialIdx;
                                partWiseIndex[index] = index;
                                if (['Front_Inset_Band', 'Back_Inset_Band', 'Back_CenterBand', 'Front_CenterBand', 'Back_Vertical_Band', 'Back_Double_Band', 'Front_Vertical_Band', 'Front_Double_Band']?.includes(part.name)) {
                                    meterialIndexies.push(index);
                                }

                            });
                            meterialIndexies.forEach(async (index) => {
                                const material = modelViewer.model.materials[index]
                                const createAndApplyTexture1 = async (channel, path) => {
                                    var texture = await modelViewer.createTexture(path);
                                    material[channel].setTexture(texture);
                                }
                                createAndApplyTexture1('normalTexture', base64URL);
                            });
                        }
                    }, 100);
                });
            }
        }, 1000)
    }

    const updateMaterial = async () => {
        let keys, material;

        keys = Object.keys(fabric.partWiseFabric);
        material = fabric;
        if (keys.length === 0) {
            return;
        }
        for (const key of keys) {
            if (material.partWiseFabric[key]) {
                let baseKey
                if (key === 'Fringe') {
                    baseKey = material.partWiseFabric[key].alphaDiffuse
                } else if (material.partWiseFabric[key]?.customBand && ['Front_Inset_Band', 'Back_Inset_Band', 'Back_CenterBand', 'Front_CenterBand', 'Back_Vertical_Band', 'Back_Double_Band', 'Front_Vertical_Band', 'Front_Double_Band', 'Fringe']?.includes(key)) {
                    baseKey = addBandFinalFabricBefore || material.partWiseFabric[key]?.finish || material.partWiseFabric[key].uploadImageUrl || material.partWiseFabric[key].diffuse
                } else {
                    baseKey = material.partWiseFabric[key].uploadImageUrl || material.partWiseFabric[key].diffuse
                }
                await createAndApplyTexture(
                    "baseColorTexture",
                    key,
                    baseKey
                    // key === 'Fringe' ? material.partWiseFabric[key].alphaDiffuse : (material.partWiseFabric[key].uploadImageUrl || material.partWiseFabric[key].diffuse)
                );
                await createAndApplyTexture(
                    "metallicRoughnessTexture",
                    key,
                    material.partWiseFabric[key].metallicRoughness,
                    material.partWiseFabric[key],
                );
                await createAndApplyTexture(
                    "normalTexture",
                    key,
                    material.partWiseFabric[key].normal,
                    material.partWiseFabric[key],
                );

                if (key === 'Front_Inset_Band') {
                    document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 105%'
                }
                const bandPart = ['Front_Inset_Band', 'Front_Vertical_Band', 'Front_CenterBand', 'Front_Double_Band']
                if (currentCustomization?.band?.name !== 'No' && currentCustomization?.band?.type === 'single' && bandPart.includes(key)) {
                    const nameToNameMap = {
                        "Inset Band": "Back_Inset_Band",
                        "Center": currentCustomization?.size?.name === '20x20' ? "Back_CenterBand" : "Back_Vertical_Band",
                        "Vertical": "Back_Double_Band",
                    }
                    await createAndApplyBlankTexture(
                        "baseColorTexture",
                        nameToNameMap[currentCustomization?.band?.name],
                        'Blank Image.png'
                    );
                }
                if (addBandFinalFabricBefore) {
                    if (customBandValue?.show) {
                        applyNormalTexure()
                    } else {
                        if (modelViewer?.model) {
                            setTimeout(() => {
                                const material = modelViewer.model.materials[0]
                                const createAndApplyTexture1 = async (channel, path) => {
                                    var texture = await modelViewer.createTexture(path);
                                    material[channel].setTexture(texture);
                                }
                                createAndApplyTexture1('normalTexture', `./normal.jpg`);
                            }, 100);
                        }
                    }
                }
            }
        }
        setLoader(false)
    };

    const createAndApplyTexture = async (channel, modelPartName, fabricImg, keyData) => {
        const base64Image = fabricImg?.search('data:image/png;base64') !== -1 ? true : false
        let imageConvert = fabricImg
        const keyFullData = keyData
        if (getCheckSupport === 'true' && !base64Image && fabricImg) {
            imageConvert = fabricImg?.replaceAll('.jpeg', '.webp')
        }
        try {
            if (modelViewer?.model) {
                let texture = null;
                if (fabricImg) {
                    if (textures[fabricImg]) {
                        texture = textures[fabricImg];
                    } else {
                        texture = await modelViewer.createTexture(base64Image ? fabricImg : `${ImageGlobalUrl.replace('app', preFix)}/${imageConvert}`);
                        textures[fabricImg] = texture;
                    }
                }

                const model = modelViewer.model;
                const parts = model[Object.getOwnPropertySymbols(model)[1]];

                const meterialIndexies = [];
                const partWiseIndex = {};

                parts.forEach((part) => {
                    const index = part.initialMaterialIdx;
                    partWiseIndex[index] = index;
                    if (modelPartName === part.name) {
                        meterialIndexies.push(index);
                    }

                });

                meterialIndexies.forEach(async (index) => {
                    const material = modelViewer.model.materials[index];
                    if (channel === 'metallicRoughnessTexture') {
                        material.pbrMetallicRoughness.metallicRoughnessTexture.setTexture(null)
                        if (keyFullData.matelnessFactor) {
                            material.pbrMetallicRoughness.setMetallicFactor(keyFullData.matelnessFactor);
                        }
                        if (keyFullData.roughnessFactor) {
                            material.pbrMetallicRoughness.setRoughnessFactor(keyFullData.roughnessFactor);
                        }
                    }
                    if (channel === 'normalTexture' && keyFullData.normalImageUrl) {
                        const imageUrl = GET_URL(keyFullData.normalImageUrl)
                        const newTexture = await modelViewer.createTexture(imageUrl);
                        if (channel.includes('base') || channel.includes('metallic')) {
                            material.pbrMetallicRoughness[channel].setTexture(newTexture);
                        } else {
                            material[channel].setTexture(newTexture);
                        }
                    }
                    if (fabricImg) {
                        if (channel.includes("base") || channel.includes("metallic")) {
                            material.pbrMetallicRoughness[channel].setTexture(null);
                            material.pbrMetallicRoughness[channel].setTexture(texture);
                        } else {
                            material[channel].setTexture(null);
                            material[channel].setTexture(texture);
                        }
                    } else {
                        material[channel].setTexture(null);
                    }
                    if (['Front_Inset_Band', 'Back_Inset_Band', 'Back_CenterBand', 'Front_CenterBand', 'Back_Vertical_Band', 'Back_Double_Band', 'Front_Vertical_Band', 'Front_Double_Band', 'Fringe']?.includes(modelPartName)) {
                        material.setAlphaMode('MASK')
                    }
                });
            }
        } catch (err) {
            console.log("🚀 ~ error file: PillowModelViewer.js:338 ~ createAndApplyTexture ~ err:", err)
            console.error(err);
        }
    };
    const createAndApplyBlankTexture = async (channel, modelPartName, fabricImg) => {
        try {
            if (modelViewer?.model) {
                let texture = null;
                if (textures[fabricImg]) {
                    texture = textures[fabricImg];
                } else {
                    texture = await modelViewer.createTexture(`${window.location.origin}/${fabricImg}`);
                    textures[fabricImg] = texture;
                }

                const model = modelViewer.model;
                const parts = model[Object.getOwnPropertySymbols(model)[1]];

                const meterialIndexies = [];
                const partWiseIndex = {};

                parts.forEach((part) => {
                    const index = part.initialMaterialIdx;
                    partWiseIndex[index] = part.name;
                    if (modelPartName === part.name || part.name === "Wood") {
                        meterialIndexies.push(index);
                    }
                });

                meterialIndexies.forEach((index) => {
                    const material = modelViewer.model.materials[index];
                    if (channel.includes("base") || channel.includes("metallic")) {
                        material.pbrMetallicRoughness[channel].setTexture(null);
                        material.pbrMetallicRoughness[channel].setTexture(texture);
                    } else {
                        material[channel].setTexture(null);
                        material[channel].setTexture(texture);
                    }
                    if (['Front_Inset_Band', 'Back_Inset_Band', 'Front_CenterBand', 'Back_CenterBand', 'Back_Vertical_Band', 'Back_Double_Band', 'Front_Vertical_Band', 'Front_Double_Band', 'Fringe']?.includes(modelPartName)) {
                        material.setAlphaMode('MASK')
                    }
                });
            }

        } catch (err) {
            console.error(err);
        }
    };

    const addToProjectHandler = () => {
        document.querySelector('model-viewer').fieldOfView = 10
        document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 90%'
        setTimeout(async () => {
            const FrontImage = document.querySelector("#model-viewer").toDataURL('image/png')
            const frontImageApply = uploadImage?.['Front'] ? uploadImage?.['Front'] : false
            const backImageApply = uploadImage?.['Back'] ? uploadImage?.['Back'] : false
            document.querySelector("#model-viewer").cameraOrbit = '180deg 75deg 90%'
            setTimeout(() => {
                const backImage = document.querySelector("#model-viewer").toDataURL('image/png')
                const bandPart = ['Front_Inset_Band', 'Front_Vertical_Band', 'Front_CenterBand', 'Front_Double_Band']
                const edgePart = ['Flange', 'Flange_2_inch', "Welt", 'Fringe']
                const borderName = currentCustomization?.border?.name !== 'No' ? currentCustomization?.border?.name : ''
                const bandName = currentCustomization?.band?.name !== 'No' ? `with ${currentCustomization?.band?.name}` : ''

                let name = '';
                const sizeName = currentCustomization?.size?.name;
                const isLumbar = sizeName === '12x20';

                if (borderName || bandName) {
                    const newBandName = bandName ? bandName?.replace('Band', '').concat(' Band') : '';
                    name = `${borderName} ${isLumbar ? 'Lumbar Pillow' : 'Pillow'} ${newBandName}`;
                } else {
                    name = isLumbar ? currentPillowState : 'Pillow'
                }

                const finalName = name?.replace(/(\d+")|\bWelt\b/g, ($0, $1) => $1 ? '' : 'Piping').trim();
                const frontFabric = fabric?.partWiseFabric['Front']
                const backFabric = fabric?.partWiseFabric['Back']
                const { size, insertType, band, border } = currentCustomization
                const yardSize = yardValue[size?.name]
                const pillowSellPrice = 55
                const pillowCostPrice = 25
                const insertTypeSellPrice = insertType?.name === 'Pillow Cover Only' ? 0 : insertType?.name === 'Down' ? 30 : 28
                // const sizeYard = yardSize.yard > 1 ? yardSize.yard : 1
                const sizeYard = yardSize.yard
                const fabricFrontValue = frontImageApply ? sizeYard * Number(inputSetValue['Front'].sellPrice) : frontFabric?.sellPrice ? sizeYard * frontFabric?.sellPrice : null
                const fabricCostFrontValue = frontImageApply ? sizeYard * Number(inputSetValue['Front'].costPrice) : frontFabric?.costPrice ? sizeYard * frontFabric?.costPrice : null
                const fabricBackValue = backImageApply ? sizeYard * Number(inputSetValue['Back'].sellPrice) : backFabric?.sellPrice ? sizeYard * backFabric?.sellPrice : null
                const fabricCostBackValue = backImageApply ? sizeYard * Number(inputSetValue['Back'].costPrice) : backFabric?.costPrice ? sizeYard * backFabric?.costPrice : null
                const parts = [
                    {
                        name: size?.setSize,
                        sizeName: size?.name,
                        img: size?.image,
                        quantity: 1,
                        type: "size",
                        sellPrice: pillowSellPrice,
                        costPrice: pillowCostPrice,
                        yard: sizeYard,
                        originalPrice: pillowSellPrice
                    },
                    {
                        name: insertType?.name,
                        img: insertType?.image,
                        quantity: 1,
                        type: "insertType",
                        sellPrice: insertTypeSellPrice,
                        costPrice: insertTypeSellPrice / 2,
                        yard: 1,
                        originalPrice: insertTypeSellPrice
                    },
                    {
                        ...((frontImageApply || frontFabric?.name || inputSetValue[pillowSide]?.name) && { name: frontImageApply ? inputSetValue[pillowSide]?.name ? inputSetValue[pillowSide]?.name : 'Custom Fabric' : frontFabric?.name }),
                        img: frontImageApply ? frontImageApply : frontFabric?.finish ? GET_URL(frontFabric?.finish) : '',
                        quantity: 1,
                        type: 'Front',
                        image: FrontImage,
                        vendor: frontFabric?.vendor || inputSetValue['Front'].Vendor || '',
                        costPrice: fabricCostFrontValue,
                        sellPrice: fabricFrontValue,
                        yard: sizeYard,
                        materialInsertType: frontFabric?.materialInsertType || '',
                        originalPrice: frontImageApply ? Number(inputSetValue['Front'].sellPrice) : frontFabric?.sellPrice
                    },
                    {
                        ...((backImageApply || backFabric?.name || inputSetValue[pillowSide]?.name) && { name: backImageApply ? inputSetValue[pillowSide]?.name ? inputSetValue[pillowSide]?.name : 'Custom Fabric' : backFabric?.name }),
                        img: backImageApply ? backImageApply : backFabric?.finish ? GET_URL(backFabric?.finish) : '',
                        quantity: 1,
                        type: 'Back',
                        image: backImage,
                        vendor: backFabric?.vendor || inputSetValue['Back'].Vendor || '',
                        costPrice: fabricCostBackValue,
                        sellPrice: fabricBackValue,
                        yard: sizeYard,
                        materialInsertType: backFabric?.materialInsertType || '',
                        originalPrice: backImageApply ? Number(inputSetValue['Back'].sellPrice) : backFabric?.sellPrice
                    }
                ]
                for (let key in fabric?.partWiseFabric) {
                    const value = fabric?.partWiseFabric[key]
                    if (bandPart?.includes(key) && band?.name !== 'No') {
                        const yardSide = band?.type === 'double' ? 2 : 1
                        const sizeYard = yardSize[band?.name] * yardSide
                        const bandSellValue = sizeYard * value?.sellPrice
                        const bandCostValue = sizeYard * value?.costPrice
                        const bandCost = bandCostPriceCalulate[band.name]
                        const bandSell = bandSellPriceCalulate[band.name]
                        const base64Image = value?.finish?.search('data:image') !== -1 ? true : false
                        parts.push({
                            name: band?.name,
                            img: value?.finish ? base64Image ? addBandFinalFabricBefore || value?.finish : GET_URL(value?.finish) : '',
                            quantity: 1,
                            type: 'band',
                            selectedKey: key,
                            bandType: band?.type,
                            finishName: value?.name || 'Custom Band',
                            vendor: value?.vendor,
                            costPrice: bandCostValue,
                            sellPrice: bandSellValue,
                            laberCostPrice: bandCost,
                            laberSellPrice: bandSell,
                            materialInsertType: value?.materialInsertType || '',
                            yard: sizeYard,
                            originalPrice: value?.sellPrice
                        })
                    }
                    if (edgePart?.includes(key) && border?.name !== 'No') {
                        // const sizeYard = yardSize[border.name] > 1 ? yardSize[border.name] : 1
                        const sizeYard = yardSize[border.name]
                        const borderSellValue = sizeYard * value?.sellPrice
                        const borderCostValue = sizeYard * value?.costPrice
                        const borderCost = edgeCostPriceCalulate[border.name]
                        const borderSell = edgeSellPriceCalulate[border.name]
                        parts.push({
                            name: border.name,
                            img: value?.finish ? GET_URL(value?.finish) : '',
                            quantity: 1,
                            type: 'border',
                            finishName: value?.name,
                            vendor: value?.vendor || '',
                            laberCostPrice: borderCost,
                            laberSellPrice: borderSell,
                            sellPrice: borderSellValue,
                            costPrice: borderCostValue,
                            materialInsertType: value?.materialInsertType || '',
                            yard: sizeYard,
                            originalPrice: value?.sellPrice
                        })
                    }
                }
                const uniqueData = Array.from(new Set(parts.map(item => item.type))).map(type => {
                    return parts.reverse().find(item => item.type === type);
                });
                const checkSameName = addToProject?.filter(data => data?.name === finalName)
                let checkData
                if (checkSameName?.length > 0) {
                    checkData = addToProject?.findIndex(data => {
                        const updateImage = data?.parts?.map(d => d?.image ? { ...d, image: '' } : d)
                        const uniqueImage = uniqueData?.map(d => d?.image ? { ...d, image: '' } : d)
                        return JSON.stringify(updateImage) === JSON.stringify(uniqueImage)
                    })
                } else {
                    checkData = -1
                }

                if (checkData === -1) {
                    // const cehckIndex = addToProject?.filter(data => data?.type === "pillow")?.length
                    const cehckIndex = addToProject?.length ? Number(addToProject?.[0]?.seriesName?.slice(1)) : 0
                    const sumCalculate = Calculator(uniqueData, 1)
                    let newPaint = {}
                    for (let key in fabric?.partWiseFabric) {
                        const value = fabric?.partWiseFabric[key]
                        newPaint = { ...newPaint, [key]: value?._id }
                    }
                    const updateFabric = {
                        ...fabric,
                        partWiseFabric: newPaint
                    }
                    const addData = {
                        name: finalName,
                        type: 'pillow',
                        parts: uniqueData,
                        quantity: 1,
                        base64Image: FrontImage,
                        base64ImageBack: backImage,
                        fabric: updateFabric,
                        sumCalculate,
                        inputValue,
                        offsetValue,
                        modelAtomValue: {
                            ...modelAtomValue,
                            src: document.querySelector("#model-viewer").src || modelAtomValue.src
                        },
                        currentCustomization,
                        seriesName: cehckIndex + 1 > 9 ? `S${cehckIndex + 1}` : `S0${cehckIndex + 1}`
                    }
                    setAddToProjectImage([FrontImage, ...addToProjectImage])
                    setAddToProject([addData, ...addToProject])
                } else {
                    const updatedData = addToProject?.map((data, index) => {
                        if (index === checkData) {
                            const sumCalculate = Calculator(data?.parts, data.quantity + 1)
                            return { ...data, quantity: data.quantity + 1, sumCalculate, parts: data?.parts }
                        } else {
                            return data
                        }
                    })
                    setAddToProject(updatedData)
                }
                document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 105%'
                setLoader(false)
            }, 1000)
        }, 1000)
    }

    document.querySelector("#model-viewer")?.addEventListener("progress", (event) => {
        if (event.detail.totalProgress === 1) {
            setModelAtomValue({
                ...modelAtomValue,
                loading: 100,
            })
            setLoader(false)
        }
    })

    if (modelAtomValue.src && preFix) {
        return (
            <>
                <div style={{ position: 'relative' }}>
                    <div id="card" style={{
                        opacity: '1',
                        transition: 'opacity 1s ease-in-out'
                    }}>
                        <model-viewer
                            tone-mapping="commerce"
                            src={modelAtomValue.src.replace('app', preFix)}
                            camera-controls
                            disable-pan
                            style={{ height: "57vh", width: "100%" }}
                            id="model-viewer"
                            exposure="0.8"
                        ></model-viewer>
                    </div>
                </div>
                <button
                    className="btn btn-outline-primary mb-3"
                    style={{
                        maxWidth: 50,
                        width: '100%',
                        margin: '0 auto'
                    }}
                    onClick={() => addToProjectHandler()}
                >
                    Add To Project
                </button>
            </>
        )
    } else {
        return <></>
    }
}

export default PillowModelViewer