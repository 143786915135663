import React from 'react';

const Categories = ({ setCategory, category, modelSrc, loader }: { setCategory: Function, loader: boolean, category: string, modelSrc: any }) => {
    const hostName = window.location.host !== 'design.suitepieces.com'
    return (
        <div className="container">
            <h2 className="title text-center">Featured Category</h2>
            <h5 className="sub-title mb-4 text-center">
                Select our customization product shop to see amazing creations from
                our designers.
            </h5>
            <div className="d-flex align-items-center justify-content-center ">
                <div className={`box mx-3 mt-3 cursor-pointer text-center `}
                    onClick={() => {
                        // if (modelSrc.loading !== 100) {
                        if (loader) {
                        } else {
                            setCategory("vintage")
                        }
                    }}
                >
                    <img
                        src="vintage.png"
                        className={`img-fluid badge-img ${category === 'vintage' ? 'active' : ''}`}
                        alt="vinatge"
                        title='vinatge'
                    />
                    <span className="badge" style={{ opacity: "0.9" }}>
                        Vintage
                    </span>
                </div>

                <div className={`mt-3 box mx-3 cursor-pointer text-center `}
                    onClick={() => {
                        // if (modelSrc.loading !== 100) {
                        if (loader) {
                        } else {
                            setCategory("pillow")
                        }
                    }}
                >
                    <img
                        src="pillow.png"
                        className={`img-fluid badge-img ${category === 'pillow' ? 'active' : ''}`} alt="pillow" title='pillow'

                    />
                    <span className="badge" style={{ opacity: "0.9" }}>
                        Pillow
                    </span>
                </div>
                {/* Drapery changes */}

                {/* prod changes */}
                {hostName &&
                    <>
                        {/* <div className=" mt-3 box mx-3 cursor-pointer text-center" onClick={() => setCategory("drapery")}>
                            <img
                                src="drapery.svg"
                                className={`img-fluid badge-img ${category === 'drapery' ? 'active' : ''}`}
                                alt="drapery" title='drapery'
                            />
                            <span className="badge" style={{ opacity: "0.9" }}>
                                Drapery
                            </span>
                        </div> */}
                        <div className={`mt-3 box mx-3 cursor-pointer text-center `}
                            onClick={() => {
                                // if (modelSrc.loading !== 100) {
                                if (loader) {
                                } else {
                                    setCategory("upholstery")
                                }
                            }}
                        >
                            <img
                                src="upholstery.png"
                                className={`img-fluid badge-img ${category === 'upholstery' ? 'active' : ''}`}
                                alt="upholstery" title='upholstery'
                            />
                            <span className="badge" style={{ opacity: "0.9" }}>
                                Upholstery
                            </span>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Categories;