import React from "react";

const Footer = () => {
    return (
        <section className="footer section text-center">
            <div className="social-links">
                <ul>
                    <li>
                        <a
                            title="Facebook"
                            className="facebook"
                            target="_blank"
                            href="https://www.facebook.com/suitepieces" rel="noreferrer"
                        >
                            <img
                                src="https://cdn.shopify.com/s/files/1/0094/2584/6331/t/29/assets/facebook.png?v=147725485453893116311652865987"
                                alt="Facebook"
                            />
                        </a>
                    </li>

                    <li>
                        <a
                            title="Instagram"
                            className="instagram"
                            target="_blank"
                            href="https://www.instagram.com/suitepieces/" rel="noreferrer"
                        >
                            <img
                                src="https://cdn.shopify.com/s/files/1/0094/2584/6331/t/29/assets/insta.png?v=120926959140571136691652865997"
                                alt="Insta"
                            />
                        </a>
                    </li>

                    <li>
                        <a
                            title="Pinterest"
                            className="pinterest"
                            target="_blank"
                            href="https://www.pinterest.com/suitepieces" rel="noreferrer"
                        >
                            <img
                                src="https://cdn.shopify.com/s/files/1/0094/2584/6331/t/29/assets/pinterest.png?v=162256165233191856561652866002"
                                alt="Pinterest"
                            />
                        </a>
                    </li>

                    <li>
                        <a
                            title="Youtube"
                            className="youtube"
                            target="_blank"
                            href="https://www.youtube.com/channel/UCxw_R_e7C65A1rH_d9SfoOw/feed"
                        >
                            <img
                                src="https://cdn.shopify.com/s/files/1/0094/2584/6331/t/29/assets/youtube.png?v=151294988081805194821652866005"
                                alt="Youtube"
                            />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="copyright">
                <span className="copy">
                    Copyright
                    <span className="copyright--shop_name">
                        <a href="/" title="">
                            SuitePieces
                        </a>
                        2023.
                    </span>
                </span>
            </div>
        </section>
    )
}

export default Footer;