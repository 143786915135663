import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Calculator } from "../../../components/Calculator";
import { addToProjectAtom, addToProjectImageAtom } from "../../../state";
import AccessLinkPage from "./AccessLinkPage";
import SaveProjectModal from "./SaveProjectModal";

const OrderSummery = () => {
    const [modalShow, setModalShow] = useState({
        modalOne: false,
        modalTwo: false
    });
    const [addToProject, setAddToProject] = useRecoilState(addToProjectAtom);
    const [uniqueCode, setUniqueCode] = useState("");
    const [productSummary, setProductSummary] = useState([]);
    const [addToProjectImage, setAddToProjectImage] = useRecoilState(addToProjectImageAtom);

    // Add and remove quantity handler
    const quentityHandler = (type, index, quen) => {
        if (quen) {
            const updateData = addToProject?.map((data, i) => {
                if (i === index) {
                    const update = data?.parts?.map(part => part?.type === type ? { ...part, quantity: quen } : part)
                    const newData = { ...data, parts: update };
                    return newData
                } else {
                    return data
                }
            })
            setAddToProject(updateData);
        }
    }

    const pillowQuentityHandler = (index, quen) => {
        if (quen) {
            const updateData = addToProject?.map((data, i) => {
                const sumCalculate = Calculator(data?.parts, quen)
                return i === index ? { ...data, quantity: quen, sumCalculate, parts: data?.parts } : data
            })
            setAddToProject(updateData);
        }
    }

    // remove part handler
    const removeItem = (index, id) => {
        let indexRemove = null
        const updateData = addToProject.map((data, i) => {
            if (i === index) {
                const update = data?.parts?.filter((part, partIndex) => partIndex !== id);
                if (update?.length) {
                    return { ...data, parts: update }
                } else {
                    indexRemove = i
                    return null
                }
            }
            return data;
        }).filter(Boolean);
        if (indexRemove !== null) {
            const updateImage = addToProjectImage.filter((data, i) => indexRemove !== i)
            setAddToProjectImage(updateImage);
            indexRemove = null
        }
        setAddToProject(updateData);
    }

    // remove product
    const removeProduct = (index) => {
        const updateData = addToProject.filter((data, i) => index !== i)
        const updateImage = addToProjectImage.filter((data, i) => index !== i)
        setAddToProject(updateData);
        setAddToProjectImage(updateImage);
    }

    // render add project item
    const renderItem = (summary, index) => {
        return <div key={`${summary?.name} ${index}`}>
            <div className="container text-center p-0">
                <div className="container p-0">
                    <div className="cart border-bottom">
                        <div className="row">
                            <div className="col-12 col-sm-4 col-md-6 col-lg-4">
                                <div className="cart-item d-flex align-items-center justify-content-start">
                                    <div style={{ width: '70%' }}>
                                        <img src={summary?.base64Image} className="img-fluid" alt="badge-img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-6 d-flex text-start py-4">
                                <div>
                                    <p className="m-0">Name: {summary?.name}</p>
                                    <p className="m-0 color-gray">Paint: {summary?.fabric?.paint?.name}</p>
                                    <p className="m-0 color-gray">Finish: {summary?.alphaSelect?.name}</p>
                                    <p className="m-0 color-gray">Hardware: {summary?.fabric?.handle || 'Plain Fabric'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row border-bottom pb-4 ">
                {summary?.parts?.map((data, i) => {
                    return <div className="col-lg-4 col-md-6 col-sm-12 mt-sm-3 pt-4 d-flex" key={`${data?.name} ${i}`}>
                        <div className="vintage-summary-part">
                            <div className="cart-item d-flex align-items-center justify-content-start">
                                <div className="cart-item-img box-shadow-none">
                                    <img src={data?.img} className="img-fluid" alt="badge-img" />
                                </div>
                            </div>
                            <div className="quantity-box">
                                <div className="cursor-pointer width-33" onClick={() => quentityHandler(data?.type, index, data?.quantity - 1)}>
                                    <span>-</span>
                                </div>
                                <div className="width-33">{data?.quantity}</div>
                                <div className="cursor-pointer width-33" onClick={() => quentityHandler(data?.type, index, data?.quantity + 1)}>
                                    <span>+</span>
                                </div>
                            </div>
                        </div>
                        <div className="vintage-summary-second-part">
                            <div className="ms-3">
                                <p className="d-flex text-start ">{data?.name}</p>
                                <p className="color-gray d-flex cursor-pointer" onClick={() => removeItem(index, i)}>
                                    Remove X
                                </p>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    }

    const pillowRender = (summary, index) => {
        const { name: pillowName, parts: summaryPart } = summary;
        const findPart = (type) => summaryPart?.find(data => data?.type === type);
        const pillowFront = findPart('Front');
        const pillowBack = findPart('Back');
        const pillowSize = findPart('size')?.name || '';
        const pillowEdge = findPart('edge') || findPart('border');
        const pillowBand = findPart('band');
        const insertType = findPart('insertType');

        return <div className="container text-center p-0">
            <div className="container p-0">
                <div className="cart py-4 border-bottom"
                    key={`${summary?.name} ${index}`}
                >
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-4" style={{ marginBottom: 10 }}>
                            <div className="cart-item d-flex align-items-center justify-content-start">
                                <div>
                                    <div className="cart-item-img d-flex align-center box-shadow-none" style={{ marginRight: 10 }}>
                                        {pillowFront?.image ?
                                            <img src={pillowFront?.image} className="img-fluid" alt="badge-img" />
                                            :
                                            <img src={'defalutImage.jpg'} className="img-fluid" alt="badge-img" />
                                        }
                                    </div>
                                    <p className="m-0">Front</p>
                                </div>
                                <div>
                                    <div className="cart-item-img d-flex align-center box-shadow-none">
                                        {pillowBack?.image ?
                                            <img src={pillowBack?.image} className="img-fluid" alt="badge-img" />
                                            :
                                            <img src={'defalutImage.jpg'} className="img-fluid" alt="badge-img" />
                                        }
                                    </div>
                                    <p className="m-0">Back</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-5 d-flex text-start">
                            <div>
                                <p className="m-0">Name: {pillowName}</p>
                                <p className="m-0 color-gray">Size: {pillowSize}</p>
                                {pillowFront?.name && <p className="m-0 color-gray">Front: {pillowFront?.name}</p>}
                                {pillowBack?.name && <p className="m-0 color-gray">Back: {pillowBack?.name}</p>}
                                {pillowEdge && <p className="m-0 color-gray">{pillowEdge?.name}: {pillowEdge?.finishName}</p>}
                                {pillowBand && <p className="m-0 color-gray">{pillowBand?.name}: {pillowBand?.finishName}</p>}
                                {insertType && <p className="m-0 color-gray">Insert: {insertType?.name}</p>}
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 d-flex justify-content-between">
                            <div className="qty-rmv ">
                                <div className="quantity " style={{ userSelect: 'none' }}>
                                    <p className="quantity__minus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity - 1)}
                                    >
                                        <span>-</span>
                                    </p>
                                    <input
                                        name="quantity"
                                        type="text"
                                        className="quantity__input"
                                        value={summary?.quantity}
                                        onChange={(e) => pillowQuentityHandler(index, Number(e.target.value))}
                                    />
                                    <p className="quantity__plus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity + 1)}
                                    >
                                        <span>+</span>
                                    </p>
                                </div>
                            </div>
                            <div className="price text-right" style={{ marginTop: 10 }}>
                                <p className="multi-qty-price price-text mb-0">${summary?.sumCalculate?.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const upholsteryRender = (summary, index) => {
        const { armStyle, seatCushion, backPillow, baseOrLeg, nailhead } = summary?.upholsteryItem
        const { Base, Legs } = summary?.paintName

        return <div className="container text-center p-0">
            <div className="container p-0">
                <div className="cart pb-4 border-bottom"
                    key={`${summary?.name} ${index}`}
                >
                    <div className="row">
                        <div className="col-12 col-sm-4 col-md-6 col-lg-4">
                            <div className="cart-item d-flex align-items-center justify-content-start">
                                <div>
                                    <img src={summary?.base64Image} className="img-fluid" alt="badge-img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-6 d-flex text-start pt-4">
                            <div>
                                <p className="m-0">Name: {summary?.name}</p>
                                {Base && <p className="m-0 color-gray">Fabric: {Base}</p>}
                                <p className="m-0 color-gray">Arm Style: {armStyle?.name}</p>
                                <p className="m-0 color-gray">Seat Cushion: {seatCushion?.name}</p>
                                <p className="m-0 color-gray">Back Pillow: {backPillow?.name}</p>
                                <p className="m-0 color-gray">Base Or Leg: {baseOrLeg?.name}</p>
                                {Legs && <p className="m-0 color-gray">Base Or Leg Wood: {Legs}</p>}
                                <p className="m-0 color-gray">Nailhead: {nailhead?.name}</p>

                            </div>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 pt-4 d-flex justify-content-end">
                            <div className="qty-rmv ">
                                <div className="quantity " style={{ userSelect: 'none' }}>
                                    <p className="quantity__minus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity - 1)}
                                    >
                                        <span>-</span>
                                    </p>
                                    <input
                                        name="quantity"
                                        type="text"
                                        className="quantity__input"
                                        value={summary?.quantity}
                                        onChange={(e) => pillowQuentityHandler(index, Number(e.target.value))}
                                    />
                                    <p className="quantity__plus cursor-pointer"
                                        onClick={() => pillowQuentityHandler(index, summary?.quantity + 1)}
                                    >
                                        <span>+</span>
                                    </p>
                                </div>
                                <p className="remove-text d-flex align-items-center cursor-pointer"
                                    onClick={() => removeProduct(index)}
                                >
                                    Remove X
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <>

            {addToProject?.length ?
                <div className="container text-center">
                    <div className="container">
                        <h2 className="title">Project Summary</h2>
                        <h5 className="sub-title mb-5">
                            You will need these products for this project
                        </h5>
                        {addToProject?.map((summary, index) => {
                            return <div key={`${summary?.name} ${index}`} className='mt-3'>
                                <div className=" d-flex justify-content-between fw-400 ">
                                    <div className="fs-20">
                                        {summary?.seriesName} {summary?.name}
                                    </div>
                                    <div className="fs-16 text-red d-flex align-items-center cursor-pointer m-0" onClick={() => removeProduct(index)}>
                                        Remove
                                    </div>
                                </div>
                                <div className="border-gray mt-3"></div>
                                {summary?.type === 'vintage' ? renderItem(summary, index) : summary?.type === 'upholstery' ? upholsteryRender(summary, index) : pillowRender(summary, index)}
                            </div>
                        })}
                        <div className="d-flex align-items-center justify-content-md-end justify-content-center btn-mutiple flex-wrap">
                            <button
                                className="btn btn-outline-primary mb-3"
                                onClick={() => setModalShow({
                                    ...modalShow,
                                    modalOne: true
                                })}
                            >
                                Save Project
                            </button>
                        </div>
                    </div>
                </div>
                : <></>
            }

            {modalShow?.modalOne && <SaveProjectModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                setUniqueCode={setUniqueCode}
                setProductSummary={setProductSummary}
            />
            }
            {modalShow?.modalTwo && <AccessLinkPage
                modalShow={modalShow}
                setModalShow={setModalShow}
                setUniqueCode={setUniqueCode}
                productSummary={productSummary}
                uniqueCode={uniqueCode}
            />
            }

        </>
    )

}

export default OrderSummery;