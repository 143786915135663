import {gql} from '@apollo/client';

export const ADD_PROJECT_SUMMARY = gql`
    mutation addProductSummary($input:inputOfAddProductSummary){
    addProductSummary(input:$input ){
        _id
        name
        email
        uniqueCode
        productSummary
        type
        createdAt
        modifiedAt
    }
    }
`

export const UPDATE_PROJECT_SUMMARY = gql`
    mutation updateProductSummary($input:inputOfAddProductSummary){
        updateProductSummary(input:$input ){
        _id
        name
        email
        uniqueCode
        productSummary
        type
        createdAt
        modifiedAt
    }
    }
`

// export const ADD_VARIANT = gql`
//     mutation addVariant($data:JSON){
//         addVariant(data:$data)
//     }
// `