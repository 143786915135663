import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const history = useNavigate();
    return (
        <div className="navigation-bar">
            <nav className="navbar  bg-white">
                <div className="container text-center">
                    <div className="navbar-brand mx-auto cursor-pointer" onClick={() => history('/')} >
                        <img
                            src="https://cdn.shopify.com/s/files/1/0094/2584/6331/files/logo_2_fab14dcd-68e2-47e1-806b-64a5b79c740d_400x.png?v=1671636282"
                            alt=""
                            height="80"
                        />
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;