/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
// import "@google/model-viewer";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  modelAtom,
  preFixAtom,
  upholsteryAtom,
} from "../../state";
import { GET_URL } from "../../constans";
const textures = {};

const ShareUpholsteryModelViewer = ({ selectedProduct }) => {
  const upholstery = useRecoilValue(upholsteryAtom);
  const [modelViewer, setModelViewer] = useState(document.querySelector("#model-viewer"))
  const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
  const preFix = useRecoilValue(preFixAtom);
  const getCheckSupport = localStorage.getItem('checkSupport')

  useEffect(() => {
    const intervalId = setInterval(() => {
      setModelViewer(document.querySelector("#model-viewer"))
    }, 500);
    // Cancel the interval after 5 seconds (5000 milliseconds)
    setTimeout(() => {
      clearInterval(intervalId);
    }, 500);
  }, []);

  useEffect(() => {
    updateMaterial();
  }, [upholstery.partWiseFabric, modelAtomValue.src, modelViewer]);

  const createAndApplyTexture = async (channel, modelPartName, fabricImg, mergeImage) => {
    const base64Image = fabricImg?.search('data:image/png;base64') !== -1 ? true : false
    let imageConvert = fabricImg
    if (getCheckSupport === 'true' && !base64Image) {
      imageConvert = fabricImg?.replaceAll('.jpeg', '.webp')
    }
    try {
      if (modelViewer?.model) {
        let texture = null;
        if (textures[fabricImg]) {
          texture = textures[fabricImg];
        } else {
          texture = await modelViewer.createTexture(base64Image ? fabricImg : GET_URL(imageConvert));
          textures[fabricImg] = texture;
        }

        const model = modelViewer.model;
        const parts = model[Object.getOwnPropertySymbols(model)[1]];

        const meterialIndexies = [];
        const partWiseIndex = {};

        parts.forEach((part) => {
          const index = part.initialMaterialIdx
          partWiseIndex[index] = part.name;
          if (modelPartName === part.name) {
            meterialIndexies.push(index);
          }
        });

        meterialIndexies.forEach(index => {
          const material = modelViewer.model.materials[index];
          const pbrMR = material.pbrMetallicRoughness;

          if (channel.includes("base") || channel.includes("metallic")) {
            pbrMR[channel].setTexture(texture);
          } else {
            material[channel].setTexture(texture);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateMaterial = async () => {
    let keys, material;
    keys = Object.keys(upholstery.partWiseFabric);
    material = upholstery;

    if (keys.length === 0) {
      return;
    }
    for (const key of keys) {
      if (material.partWiseFabric[key]) {
        await createAndApplyTexture(
          "baseColorTexture",
          key,
          material.partWiseFabric[key].diffuse
        );
        await createAndApplyTexture(
          "metallicRoughnessTexture",
          key,
          material.partWiseFabric[key].metallicRoughness
        );
        await createAndApplyTexture(
          "normalTexture",
          key,
          material.partWiseFabric[key].normal
        );
      }
    }

  };

  if (modelViewer) {
    modelViewer.addEventListener("load", (data) => {
      const childrenName = document.getElementsByClassName('card mt-4 cursor-pointer p-0 me-md-4 mb-lg-0 mb-4 align-items-center active-category')?.[0]?.children[1]?.children[0]?.textContent
      if (childrenName === `${selectedProduct?.seriesName} ${upholstery?.name}`) {
        updateMaterial();
      }
    });
  }

  document.querySelector("#model-viewer")?.addEventListener("progress", (event) => {
    if (event.detail.totalProgress === 1) {
      setModelAtomValue({
        ...modelAtomValue,
        loading: 100,
      })
    }
  })
  if (modelAtomValue.src.replace('app', preFix) && preFix) {
    return (
      <>
        <div id="card" style={{
          opacity: modelAtomValue.loading === 100 ? '1' : '0',
          transition: 'opacity 1s ease-in-out'
        }}>
           <model-viewer
              tone-mapping="commerce"
            src={modelAtomValue.src.replace('app', preFix)}
            camera-controls
            disable-pan
            style={{ height: "57vh", width: "100%" }}
            id="model-viewer"
          ></model-viewer>
        </div>
      </>
    );
  } else {
    return <></>
  }
};

export default ShareUpholsteryModelViewer;
