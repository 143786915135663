import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { addToProjectAtom } from "../../../state";
import { ADD_PROJECT_SUMMARY } from "../graphQl/Mutation";
import validator from 'validator'
import { toast } from "react-toastify";
import { Notification } from "../../../lib/Notification";
import { Spin } from "antd";

const SaveProjectModal = ({ modalShow, setModalShow, setUniqueCode, setProductSummary }) => {
    const [details, setDetails] = useState({
        name: '',
        email: ''
    })
    const addToProject = useRecoilValue(addToProjectAtom);
    const [validateEmail, setValidateEmail] = useState(true);
    const [addProjectMutation] = useMutation(ADD_PROJECT_SUMMARY)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (details?.email) {
            const checkEmail = validator.isEmail(details?.email)
            setValidateEmail(checkEmail)
        } else {
            setValidateEmail(true)
        }
    }, [details?.email])

    const onSubmit = async () => {
        if (details?.name && details?.email && validateEmail) {
            const newData = addToProject?.map(data => {
                if (data?.type === 'pillow') {
                    const newPart = data?.parts?.map(partData => {
                        const { image, ...rest } = partData
                        return { ...rest }
                    })
                    return { ...data, parts: newPart }
                } else {
                    return data
                }
            })
            const hostName = window.location.host === 'design.suitepieces.com'
            const input = {
                name: details?.name,
                email: details?.email,
                productSummary: newData,
                environment: hostName
            }
            addProjectMutation({ variables: { input: input } })
                .then(({ data }) => {
                    setUniqueCode(data?.addProductSummary?.uniqueCode)
                    setProductSummary(data?.addProductSummary)
                    setModalShow({
                        ...modalShow,
                        modalOne: false,
                        modalTwo: true
                    })
                    toast.success(Notification['Save project'])
                    setLoading(false)
                }).catch(err => {
                    console.log("🚀 ~ file: SaveProjectModal.js:63 ~ .then ~ err:", err)
                    toast.error(err?.message || err)
                    setLoading(false)
                })


        } else if (!validateEmail) {
            setLoading(false)
            // toast.error("Please enter valid email address")
            setValidateEmail(false)
        } else {
            setLoading(false)
            toast.warn(Notification['Required file'])
        }
    }

    return (
        <Modal
            show={true}
            onHide={() => setModalShow({
                ...modalShow,
                modalOne: false
            })}
            className="save-modal"
        >
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Body className="w-75">
                <div className="text-center">
                    <h2 className="title mb-2 pb-1">save project</h2>
                    <h5 className="sub-title mb-5">
                        You did it! You’re one step closer to the home of your dreams!
                        Provide us the following info to save & view your design.
                    </h5>
                    <form className="text-left">
                        <label className="w-100 mb-3">
                            Name
                            <input type="text" name="name" className="w-100" value={details?.name} onChange={e => setDetails({
                                ...details,
                                name: e.target.value
                            })} />
                        </label>

                        <label className="w-100 mb-3">
                            EMAIL
                            <input type="text" name="EMAIL" className={`w-100 ${validateEmail ? '' : 'error-email'}`} value={details?.email}
                                onChange={e => setDetails({
                                    ...details,
                                    email: e.target.value
                                })} />
                        </label>
                    </form>
                    <Button
                        className="btn btn-primary mt-3"
                        onClick={() => {
                            if (!loading) {
                                setLoading(true)
                                onSubmit()
                            }
                        }}
                    >
                        Submit
                        {loading && <Spin className="submit-loader" />}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SaveProjectModal