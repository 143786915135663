import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import Footer from '../../../components/Footer'
import ShareVintageModal from '../../../components/GoogleModelViewer/ShareVintageModal'
import SharePillowModelViewer from '../../../components/GoogleModelViewer/SharePillowModelViewer'
import Header from '../../../components/Header'
import { currentCustomizationAtom, currentPillowStateAtom, fabricAtom, loaderAtom, modelAtom, offsetValueAtom, preFixAtom, selectedAlphaAtom, selectedFabricAtom, selectedImageScaletom, shareSelectedFabricAtom, upholsteryAtom, upholsteryItemAtom, uploadImageAtom, vintageAtom } from '../../../state'
import { GET_PRODUCT_SUMMARY } from '../graphQl/Query'
import ShareUpholsteryModelViewer from '../../../components/GoogleModelViewer/ShareUpholsteryModelViewer'
import AntdSpin from '../../../components/Loader/AntdSpin'
import { ImageGlobalUrl } from '../../../lib/constant'

const SharePage = () => {
    // get id from url
    const { id } = useParams()
    // state define
    const [shareData, setShareData] = useState()
    const [selectedProduct, setSelectedProduct] = useState()
    const [selectedProductIndex, setSelectedProductIndex] = useState(0)
    const [currentPillowState, setCurrentPillowState] = useRecoilState(currentPillowStateAtom);
    const [currentCustomization, setCurrentCustomization] = useRecoilState(currentCustomizationAtom);
    const [fabric, setFabricsAtomState] = useRecoilState(fabricAtom);
    const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
    const [alphaSelect, setAlphaSelect] = useRecoilState(selectedAlphaAtom)
    const [selectedFabric, setSelectedFabric] = useRecoilState(selectedFabricAtom);
    const [vintageAtomState, setVintageAtomState] = useRecoilState(vintageAtom);
    const [upholsteryItem, setUpholsteryItem] = useRecoilState(upholsteryItemAtom)
    const [upholsteryAtomState, setUpholsteryAtomState] = useRecoilState(upholsteryAtom);
    const [uploadImage, setUploadImage] = useRecoilState(uploadImageAtom)
    const [loader, setLoader] = useRecoilState(loaderAtom)
    const [offsetValue, setOffsetValue] = useRecoilState(offsetValueAtom)
    const [inputValue, setInputValue] = useRecoilState(selectedImageScaletom);
    const [shareSelectedFabric, setShareSelectedFabric] = useRecoilState(shareSelectedFabricAtom);
    const preFix = useRecoilValue(preFixAtom);
    // Query 
    const [getSummary] = useLazyQuery(GET_PRODUCT_SUMMARY)

    // when id is change call getPoductSummary query
    useEffect(() => {
        if (id) {
            setLoader(true)
            const summaryFun = async () => {
                const { data } = await getSummary({
                    variables: {
                        uniqueCode: id
                    }
                })
                const selectedRecord = data?.getProductSummaryByUniCode?.productSummary?.[0]
                setShareData(data?.getProductSummaryByUniCode)
                selectProductCustomize(selectedRecord)
                setLoader(false)
            }
            summaryFun()
        }
    }, [id])

    const selectProductCustomize = (data) => {
        setSelectedProduct(data)
        if (data?.type === 'pillow') {
            const { modelAtomValue, fabric, name, currentCustomization, parts, inputValue, offsetValue } = data;
            const FrontPart = parts.find(item => item.type === 'Front')
            const bandPart = parts.find(item => item.type === 'band')
            const BackPart = parts.find(item => item.type === 'Back')
            setShareSelectedFabric(bandPart)
            let uploadedImage = {}
            if (FrontPart?.name === 'Custom Fabric') {
                uploadedImage['Front'] = FrontPart?.img
            }
            if (BackPart?.name === 'Custom Fabric') {
                uploadedImage['Back'] = BackPart?.img
            }
            setInputValue(inputValue)
            setOffsetValue(offsetValue)
            setUploadImage(uploadedImage)
            setModelAtomValue(modelAtomValue)
            setFabricsAtomState(fabric)
            setCurrentPillowState(name)
            setCurrentCustomization(currentCustomization)
        } else if (data?.type === 'vintage') {
            const { modelAtomValue, alphaSelect, fabric } = data;
            setModelAtomValue(modelAtomValue)
            setAlphaSelect(alphaSelect)
            setVintageAtomState(fabric)
            setSelectedFabric(fabric?.paint)
        } else if (data?.type === 'upholstery') {
            const { upholsteryItem, fabric, modelAtomValue } = data;
            setModelAtomValue(modelAtomValue)
            setUpholsteryItem(upholsteryItem)
            setUpholsteryAtomState(fabric)
        }
    }

    const pillowRender = () => {
        const FrontPart = selectedProduct?.parts.find(item => item.type === 'Front')
        const BackPart = selectedProduct?.parts.find(item => item.type === 'Back')
        const sizePart = selectedProduct?.parts.find(item => item.type === 'size')
        const borderPart = selectedProduct?.parts.find(item => item.type === 'border')
        const bandPart = selectedProduct?.parts.find(item => item.type === 'band')
        const insertType = selectedProduct?.parts.find(item => item.type === 'insertType')

        return (
            <div className='w-100'>
                <p> {selectedProduct?.name}</p>
                <p> ${selectedProduct?.sumCalculate?.toFixed(2)}</p>
                <div className='border-gray'></div>
                <div>
                    <p className='color-gray my-2'>Size: {sizePart?.name}</p>
                </div>
                {/* {(FrontPart?.name || FrontPart?.name) && <>
                    <div className='border-gray'></div>
                    <div>
                        <p className='my-2'>FABRIC</p>
                        {FrontPart?.name && <p className='color-gray'>Front: {FrontPart?.name}</p>}
                        {BackPart?.name && <p className='color-gray'>Back: {BackPart?.name}</p>}
                    </div>
                </>
                }
                {borderPart?.name && <>
                    <div className='border-gray'></div>
                    <div>
                        <p className='my-2'>EDGE</p>
                        <p className='color-gray'>{borderPart?.name}: {borderPart?.finishName}</p>
                    </div>
                </>}
                {bandPart?.name && <>
                    <div className='border-gray'></div>
                    <div>
                        <p className='my-2'>BAND</p>
                        <p className='color-gray'>{bandPart?.name}: {bandPart?.finishName}</p>
                    </div>
                </>}
                {insertType?.name && <>
                    <div className='border-gray'></div>
                    <div>
                        <p className='color-gray my-2'>Insert: {insertType?.name}</p>
                    </div>
                </>} */}
            </div>
        )
    }

    const upholsteryRender = () => {
        const { armStyle, seatCushion, backPillow, baseOrLeg, nailhead } = selectedProduct?.upholsteryItem
        const { Base, Legs } = selectedProduct?.paintName

        return (
            <div className='w-100'>
                <p> {selectedProduct?.name}</p>
                {Base && <>
                    <div className='border-gray my-2'></div>
                    <p className="m-0 color-gray">Fabric: {Base}</p>
                </>
                }
                <div className='border-gray my-2'></div>
                <p className="m-0 color-gray">Arm Style: {armStyle?.name}</p>
                <div className='border-gray my-2'></div>
                <p className="m-0 color-gray">Seat Cushion: {seatCushion?.name}</p>
                <div className='border-gray my-2'></div>
                <p className="m-0 color-gray">Back Pillow: {backPillow?.name}</p>
                <div className='border-gray my-2'></div>
                <p className="m-0 color-gray">Base Or Leg: {baseOrLeg?.name}</p>
                {Legs && <>
                    <div className='border-gray my-2'></div>
                    <p className="m-0 color-gray">Base Or Leg Wood: {Legs}</p>
                </>
                }
                <div className='border-gray my-2'></div>
                <p className="m-0 color-gray">Nailhead: {nailhead?.name}</p>
            </div>
        )
    }

    const vintageRender = () => {
        const paintPart = selectedProduct?.parts.find(item => item.type === 'paint')
        const alphaPart = selectedProduct?.parts.find(item => item.type === 'alpha')
        const handlePart = selectedProduct?.parts.find(item => item.type === 'handle')
        const alphaTitle = alphaPart?.name?.toLocaleLowerCase()?.search('wax') !== -1 ? 'Wax' : 'Glaze'

        return (
            <div className='w-100'>
                <p> {selectedProduct?.name}</p>
                <div className='border-gray'></div>
                <div>
                    <p className='my-2'>PAINT</p>
                    <p className='color-gray'>{paintPart?.name}</p>
                </div>
                <div className='border-gray'></div>
                <div>

                    <p className='my-2'>{alphaTitle}</p>
                    <p className='color-gray'>{alphaPart?.name}</p>
                </div>
                {handlePart?.name && <>
                    <div className='border-gray'></div>
                    <div>
                        <p className='my-2'>HANDLE</p>
                        <p className='color-gray'>{handlePart?.name}</p>
                    </div>
                </>}
            </div>
        )
    }

    return (
        <>
            <Header />
            {loader && !shareData?.productSummary && <AntdSpin />}
            <div className="container-fluid summary-header-card pt-4 pb-5">
                <div>
                    <h5 className="title text-center">PROJECT SUMMARY</h5>
                    <h6 className="sub-title mt-4 text-center">{shareData?.name}</h6>
                    <p className="sub-title text-center color-gray">{shareData?.email}</p>
                    <div className='row justify-content-center'>
                        {shareData?.productSummary?.map((data, index) => {
                            const selected = selectedProductIndex === index
                            return (
                                <div
                                    key={`${data?.name} ${index}`}
                                    className={`card mt-4 cursor-pointer p-0 me-md-4 mb-lg-0 mb-4 align-items-center ${selected ? 'active-category' : 'border-gray'}`}
                                    style={{ width: '18rem' }}
                                    onClick={() => {
                                        setSelectedProductIndex(index)
                                        selectProductCustomize(data)
                                        setLoader(true)
                                    }}>
                                    <div className='d-flex justify-content-center align-items-center' style={{ width: 200, height: 225 }} >
                                        <img className="card-img-top" src={`${ImageGlobalUrl.replace('app', preFix)}/${data?.base64}`} alt="Card image cap"
                                            style={{ maxHeight: "100%" }}
                                        />
                                    </div>
                                    <div className="card-body text-center border-top-gray w-100" >
                                        <p className={`${selected ? 'text-black' : 'color-gray'} card-text  `}>
                                            {/* {data?.type === 'vintage' ? 'Vintage' : `${data?.seriesName} Pillow`} */}
                                            {data?.seriesName} {data?.name}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="product-info-row container">
                <div className="row ">
                    <div className="col-lg-6 col-xl-6 left-part mt-rem-6 mt-0 d-flex justify-content-between flex-column " style={{ height: '57vh' }}>
                        {selectedProduct?.type === 'pillow' && modelAtomValue?.src ? <SharePillowModelViewer /> :
                            selectedProduct?.type === 'vintage' && modelAtomValue?.src ? <ShareVintageModal selectedProduct={selectedProduct} /> :
                                selectedProduct?.type === 'upholstery' ? <ShareUpholsteryModelViewer selectedProduct={selectedProduct} /> : <></>}
                    </div>
                    <div className="col-lg-6 col-xl-6 right-part text-left mobile-shadow mt-4">
                        <div className="d-flex align-items-stretch tab-item-wrapper">
                            {selectedProduct?.type === 'pillow' ? pillowRender() : selectedProduct?.type === 'vintage' ? vintageRender() : selectedProduct?.type === 'upholstery' ? upholsteryRender() : ''}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SharePage