import { Drawer, InputNumber, Space } from 'antd'
import React, { useState, createRef, useEffect } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from 'recoil'
import { addBandFabricBeforeAtom, addBandFinalFabricBeforeAtom, addFabricBeforeAtom, addFabricToggleAtom, addNewCustomFabricAtom, aspectRationAtom, beforeSelectedImageScaletom, boxDimentionAtom, cropperSelectionAtom, currentCustomizationAtom, customBandValueAtom, fabricAtom, pillowSideAtom, selectedAllBandAtom, selectedBandAtom, selectedCustomFabricAtom, selectedImageScaletom, showBandModalAtom, showModalAtom, uploadImageAtom } from '../../state'
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import { DebounceInput } from 'react-debounce-input';
import { v4 as uuidv4 } from 'uuid';

const BandCropImage = () => {
    const cropperImageRef = createRef();
    const [showModal, setShowModal] = useRecoilState(showModalAtom)
    const [addBandFabricBefore, setAddBandFabricBefore] = useRecoilState(addBandFabricBeforeAtom);

    const [addFabricToggle, setAddFabricToggle] = useRecoilState(addFabricToggleAtom)
    const [validation, setValidation] = useState([])
    const [cropperData, setCropperData] = useRecoilState(cropperSelectionAtom)
    const [selectedCustomFabric, setSelectedCustomFabricAtom] = useRecoilState(selectedCustomFabricAtom)
    const [showBandModal, setShowBandModal] = useRecoilState(showBandModalAtom)
    const [addBandFinalFabricBefore, setAddBandFinalFabricBefore] = useRecoilState(addBandFinalFabricBeforeAtom)
    const [selectedAllBand, setselectedAllBand] = useRecoilState(selectedAllBandAtom)
    const [selectedBandImage, setSelectedBandImage] = useState("")
    const [selectedBand, setSelectedBand] = useRecoilState(selectedBandAtom)
    const [customBandValue, setCustomBandValue] = useRecoilState(customBandValueAtom)
    const [fabricAtomState, setFabricsAtomState] = useRecoilState(fabricAtom);

    useEffect(() => {
        setTimeout(() => {
            const cropper = cropperImageRef?.current?.cropper;
            if (cropperData && cropperData.height && cropperData.width && cropper) {
                cropper.setData(cropperData)
            }
        }, 1000)
    }, [])


    useEffect(() => {
        onCrop1()
    }, [customBandValue])

    const onChange = (newValue, key) => {
        const changeData = {
            ...customBandValue,
            [key]: newValue
        }
        setCustomBandValue(changeData);

        const filerData = validation?.filter(data => data !== key)
        setValidation(filerData)

    };

    const submitHandler = () => {
        // setValidation([])
        const uniqueId = uuidv4()
        let customData = {
            ...selectedCustomFabric,
            ...customBandValue,
            // finish: selectedBandImage,
            customBand: true,
            cropperData,
            originalImage: addBandFabricBefore
        }
        if (selectedBandImage) {
            customData = { ...customData, finish: selectedBandImage }
        }
        if (customData?.id) {
            const newData = selectedAllBand?.map(data => data.id === customData?.id ? customData : data)
            setselectedAllBand(newData)
        } else {
            customData = { ...customData, id: uniqueId }
            setselectedAllBand([customData, ...selectedAllBand])
        }
        if (fabricAtomState?.currentPart?.length > 0) {
            const partWiseFabric = fabricAtomState.currentPart.reduce((acc, part) => {
                acc[part] = customData
                return acc
            }, {})
            const newFabric = { ...fabricAtomState, partWiseFabric: { ...fabricAtomState.partWiseFabric, ...partWiseFabric } };
            setFabricsAtomState({
                ...newFabric,
            })
        }
        setSelectedCustomFabricAtom(customData)
        setSelectedBand(customData)
        if (selectedBandImage) {
            setAddBandFinalFabricBefore(selectedBandImage)
        }
        setShowModal(false)
        setShowBandModal(false)
        setAddFabricToggle(true)
    }

    const onCrop1 = async () => {
        const verticalVal = document.getElementById('Vertical Size')?.value
        const cropper = cropperImageRef?.current?.cropper;
        if (cropper) {
            const selectionCropperData = cropper.getData()
            setCropperData(selectionCropperData)
        }
        if (Number(verticalVal)) {
            var inch = verticalVal
            const finalinch = inch * 156.25
            console.log(finalinch)
            // const selectionCropperData = cropper.getData()
            const imgData = cropper?.getCroppedCanvas().toDataURL()
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = 2048;
            canvas.height = 2048;
            var canvasWidth = canvas.width;
            var canvasHeight = canvas.height;
            const final_img = imgData
            var image = new Image();
            image.src = final_img;
            await image.decode();
            const { height, width } = image;

            // Set the fixed height for the image
            var imageHeight = finalinch;
            var aspectRatio = width / height;

            // Calculate the new width based on the fixed height
            var imageWidth = imageHeight * aspectRatio;

            // Clear the canvas
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            // Draw the repeating images horizontally
            var xPos = 0;
            while (xPos < canvasWidth) {
                ctx.drawImage(image, xPos, (canvasHeight - imageHeight) / 2, imageWidth, imageHeight);
                xPos += imageWidth;
            }

            var imgData1 = canvas.toDataURL("image/png");
            setSelectedBandImage(imgData1)
            // Create a download link
            // var a = document.createElement("a");
            // a.href = imgData1;
            // a.download = "transparent_canvas.png";

            // // Trigger a click event on the download link to initiate the download
            // a.click();


        }
    };


    return (

        <Drawer
            title="Step 1: Size of the fabric"
            placement="top"
            closable={false}
            onClose={() => {
                if (selectedCustomFabric?.id) {
                    submitHandler()
                } else {
                    setShowModal(false)
                    setShowBandModal(false)
                }
            }}
            open={true}
            key={"placement"}
            className="Customization-drawer"
            extra={
                <Space>
                    <div
                        className="cursor-pointer d-flex justify-content-end"
                        onClick={() => {
                            if (selectedCustomFabric?.id) {
                                submitHandler()
                            } else {
                                setShowModal(false)
                                setShowBandModal(false)
                            }
                        }}
                        style={{ color: 'black' }}
                    >
                        X
                    </div>
                </Space>
            }
        >
            <div className="row " style={{ height: 'calc(100vh - 57px)' }}>
                <div className="col-lg-9  col-xl-9 left-part mt-rem-6 mt-0" >
                    <div style={{
                        flexDirection: 'column',
                        height: 'calc(100vh - 74px)'
                    }}>
                        <div id="container"
                            style={{ objectFit: 'contain', justifyContent: 'center' }}
                            className='h-100 w-100'
                        >
                            <div style={{ height: '100%', maxWidth: '100%', marginBottom: 10 }} className="cropper-preview-image">
                                <Cropper
                                    ref={cropperImageRef}
                                    style={{ height: '100%', width: "100%" }}
                                    zoomTo={0}
                                    zoomable={false}
                                    initialAspectRatio={1}
                                    preview=".img-preview"
                                    src={addBandFabricBefore}
                                    viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={0.5}
                                    checkOrientation={false}
                                    guides={true}
                                    cropend={onCrop1}
                                />
                            </div>
                        </div>
                    </div>
                    <div className=' d-flex mt-3 justify-content-center'>
                    </div>
                </div>
                <div className="col-lg-3 py-3 col-xl-3 right-part text-left mobile-shadow d-flex flex-column justify-content-between"
                    style={{ boxShadow: '-8px 1px 16px 0px #00000029', paddingRight: 25 }}
                >
                    <div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Vertical Size (Inch)</p>
                            <DebounceInput
                                type="number"
                                className={`search-control maxw-100 w-100 ${validation?.includes('Vertical') ? 'repeat-validation' : ''}`}
                                placeholder={"Vertical Size"}
                                autoComplete="off"
                                id="Vertical Size"
                                value={customBandValue?.Vertical}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => onChange(Number(e.target.value), 'Vertical')}
                            />
                            {validation?.includes('Vertical') ? <p className='m-0 text-red'>Please Enter Vertical Repeat Size</p> : ''}
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Pattern No</p>
                            <DebounceInput
                                type="string"
                                className={`search-control maxw-100 w-100 `}
                                placeholder={"Pattern No"}
                                autoComplete="off"
                                value={customBandValue?.name}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => onChange(e.target.value, 'name')}
                            />
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>URL</p>
                            <DebounceInput
                                type="string"
                                className={`search-control maxw-100 w-100 `}
                                placeholder={"URL"}
                                autoComplete="off"
                                value={customBandValue?.Vendor}
                                debounceTimeout={500}
                                autoFocus={true}
                                onChange={(e) => onChange(e.target.value, 'Vendor')}
                            />
                        </div>
                        <div className='mt-3'>
                            <p className='mb-halfrem color-gray'>Fabric Price</p>
                            <InputNumber
                                value={customBandValue?.sellPrice}
                                addonAfter="Per Yard"
                                placeholder={"Fabric Price"}
                                style={{ boxShadow: 'unset' }}
                                className={`search-control maxw-100 w-100 `}
                                onChange={(value) => onChange(Number(value), 'sellPrice')}
                            />
                        </div>
                        <div className='mt-3'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='m-0 p-0 color-gray'>Genarate normal</p>
                                <input type="checkbox" className='m-0 p-0'
                                    style={{ width: 16 }}
                                    checked={customBandValue?.show}
                                    onChange={(e) => onChange(e.target.checked, 'show')}
                                />
                            </div>
                        </div>
                    </div>
                    <Form.Group as={Row} className='mt-5'>
                        <Col xs="6">
                            <Button
                                className=" w-100 w-min-126px"
                                size="lg"
                                style={{ backgroundColor: 'black', borderColor: 'black' }}
                                type="submit"
                                onClick={() => {
                                    // let validate = []
                                    // if (!inputSetValue[pillowSide]?.Horizontal) {
                                    //     validate = [...validate, 'Horizontal']
                                    // }
                                    // if (!inputSetValue[pillowSide]?.Vertical) {
                                    //     validate = [...validate, 'Vertical']
                                    // }
                                    // setValidation(validate)
                                    // if (validate?.length === 0) {
                                    submitHandler()
                                    // }
                                }}>
                                Apply
                            </Button>
                        </Col>
                        <Col xs='6'>
                            <Button className=" w-100 w-min-126px" style={{
                                border: "1px solid black",
                                backgroundColor: "white"
                            }} size="lg" variant="light" type="submit" onClick={() => {
                                if (selectedCustomFabric?.id) {
                                    submitHandler()
                                } else {
                                    setShowModal(false)
                                    setShowBandModal(false)
                                }
                            }}>
                                Cancel
                            </Button>
                        </Col>
                    </Form.Group>
                </div>
            </div>
        </Drawer>
    )
}

export default BandCropImage