/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
// import "@google/model-viewer";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  addToProjectAtom,
  addToProjectImageAtom,
  loaderAtom,
  modelAtom,
  preFixAtom,
  selectedAlphaAtom,
  selectedFabricAtom,
  vintageAtom,
} from "../../state";
import { GET_URL } from "../../constans";
import p5 from 'p5'
import { glazeArray, normalAlpha, waxArray } from "../../content";
import { Handle_Global_Url, ImageGlobalUrl } from "../../lib/constant";
import { reduceBase64ImageSize } from "../../lib/base64Reduce";
import AntdSpin from "../Loader/AntdSpin";
const textures = {};

const GoogleModelViewer = () => {
  const [imgload, setImgload] = useState('')
  const modelViewer = document.querySelector("#model-viewer");
  const paint = useRecoilValue(vintageAtom);
  const alphaSelect = useRecoilValue(selectedAlphaAtom)
  const selectedFabric = useRecoilValue(selectedFabricAtom);
  const [modelAtomValue, setModelAtomValue] = useRecoilState(modelAtom);
  const preFix = useRecoilValue(preFixAtom);
  const [addToProject, setAddToProject] = useRecoilState(addToProjectAtom);
  const [addToProjectImage, setAddToProjectImage] = useRecoilState(addToProjectImageAtom);
  const [loader, setLoader] = useRecoilState(loaderAtom)
  const getCheckSupport = localStorage.getItem('checkSupport')

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.getElementsByClassName('side-box active me-3 position-relative')?.[0]) {
        document.getElementsByClassName('side-box active me-3 position-relative')?.[0].click()
      }
    }, 200);
    // Cancel the interval after 5 seconds (5000 milliseconds)
    setTimeout(() => {
      clearInterval(intervalId);
    }, 200);
  }, [])

  const createAndApplyTexture = async (channel, modelPartName, fabricImg, checkParams) => {
    const base64Image = fabricImg?.search('data:image/png;base64') !== -1 ? true : false
    let imageConvert = fabricImg
    if (getCheckSupport === 'true' && !base64Image) {
      imageConvert = fabricImg?.replaceAll('.jpeg', '.webp')
    }
    try {
      if (modelViewer?.model) {
        let texture = null;
        if (textures[fabricImg]) {
          texture = textures[fabricImg];
        } else {
          texture = await modelViewer.createTexture(base64Image ? fabricImg : checkParams ? fabricImg : GET_URL(imageConvert));
          textures[fabricImg] = texture;
        }

        const model = modelViewer.model;
        const parts = model[Object.getOwnPropertySymbols(model)[1]];

        const meterialIndexies = [];
        const partWiseIndex = {};

        parts.forEach((part) => {
          const index = part.initialMaterialIdx;
          partWiseIndex[index] = part.name;
          if (modelPartName === part.name) {
            // if (modelPartName === part.name || part.name === "Wood") {
            meterialIndexies.push(index);
          }
          if (part.name === 'Acrylic') {
            const material = modelViewer.model.materials[index];
            material.pbrMetallicRoughness.setBaseColorFactor('#ffffff');
          }
        });

        meterialIndexies.forEach(index => {
          const material = modelViewer.model.materials[index];
          const pbrMR = material.pbrMetallicRoughness;

          if (channel.includes("base") || channel.includes("metallic")) {
            pbrMR[channel].setTexture(texture);
          } else {
            material[channel].setTexture(texture);
          }
          if (['Front_Inset_Band', 'Front_Vertical_Band', 'Front_Double_Band', 'Fringe']?.includes(modelPartName)) {
            material.setAlphaMode('MASK');
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateMaterial = async () => {
    // if (modelViewer) {
    //   modelViewer.exposure = inputValue?.Exposure
    // }
    let keys, material;
    const keyName = Object.keys(paint.partWiseFabric)
    keys = !keyName?.length ? alphaSelect?.name ? paint.currentPart : keyName : keyName;
    material = paint;

    if (keys.length === 0) {
      return;
    }
    for (const key of keys) {
      if (!material?.partWiseFabric[key]?.diffuse) {
        return;
      }
      if (['Vintage_Dresser', 'Tall_Vintage_Dresser', 'Long_Vintage_Credenza', 'Vintage_Nightstand', 'Vintage_Buffet']?.includes(paint?.name)) {
        // setModelAtomValue({
        //   ...modelAtomValue,
        //   loading: 0,
        // });
        setLoader(true)
        let alphaImgVar
        new p5(p => {
          let img1;
          let img2;
          let img3;
          let img4;
          let img5;
          p.preload = () => {
            const { name, type, index } = alphaSelect;
            const selectedArray = type === "glaze" ? glazeArray : waxArray;
            const selectedIndex = index || 1;
            const selectData = name && type ? selectedArray[selectedIndex] : selectedArray[1];

            // if (material.partWiseFabric[key]) {
            img1 = p.loadImage(`${ImageGlobalUrl}/${material.partWiseFabric[key].diffuse}`);
            // } else {
            //   img1 = p.loadImage(`Pitch1.jpg`);
            // }
            const paintToImagePropsMap = {
              'Vintage_Dresser': ["base", "alpha", "dark", "white"],
              'Tall_Vintage_Dresser': ["tallBase", "tallAlpha", "tallDark", "tallWhite"],
              'Long_Vintage_Credenza': ["longBase", "longAlpha", "longDark", "longWhite"],
              'Vintage_Buffet': ["buffetBase", "buffetAlpha", "buffetDark", "buffetWhite"],
              'Vintage_Nightstand': ["nightBase", "nightAlpha", "nightDark", "nightWhite"]
            };

            const imageProps = paintToImagePropsMap[paint?.name] || [];

            const propToKeyTypeMap = {
              'base': ['base', 'tallBase', 'longBase', 'buffetBase', 'nightBase'],
              'alpha': ['alpha', 'tallAlpha', 'longAlpha', 'buffetAlpha', 'nightAlpha'],
              'dark': ['dark', 'tallDark', 'longDark', 'buffetDark', 'nightDark'],
              'white': ['white', 'tallWhite', 'longWhite', 'buffetWhite', 'nightWhite']
            };

            for (let prop of imageProps) {
              if (prop in selectData) {
                const img = p.loadImage(selectData[prop]);
                let keyType = Object.keys(propToKeyTypeMap).find(key => propToKeyTypeMap[key].includes(prop)) || prop;
                switch (keyType) {
                  case 'base':
                    img2 = img;
                    break;
                  case 'alpha':
                    img3 = img;
                    break;
                  case 'dark':
                    img4 = img;
                    break;
                  case 'white':
                    img5 = img;
                    break;
                  default:
                    break;
                }
              }
            }
            p.setup()
          };

          p.setup = () => {
            p.createCanvas(1920, 1920);
            // p.background(0);

            img1.resize(1920, 1920);
            img1.mask(img3);

            img2.resize(1920, 1920);
            p.image(img2, 0, 0);
            p.image(img1, 0, 0);

            if (img4) {
              img4.resize(1920, 1920);
              p.image(img4, 0, 0);
            }
            if (img5) {
              img5.resize(1920, 1920);
              p.image(img5, 0, 0);
            }

            alphaImgVar = p.canvas.toDataURL()
            setImgload(p.canvas.toDataURL())
          };
        });
        setTimeout(async () => {
          await createAndApplyTexture(
            "baseColorTexture",
            key,
            alphaImgVar ? alphaImgVar : material.partWiseFabric[key].diffuse
          );
        }, 3000)

      } else {
        if (material?.partWiseFabric[key]?.diffuse || material?.partWiseFabric[key]?.alphaDiffuse) {
          await createAndApplyTexture(
            "baseColorTexture",
            key,
            material.partWiseFabric[key].diffuse
          );
        }
      }
      if (material.partWiseFabric[key]) {
        await createAndApplyTexture(
          "metallicRoughnessTexture",
          key,
          material.partWiseFabric[key].metallicRoughness
        );

        await createAndApplyTexture(
          "normalTexture",
          key,
          // alphaSelect?.type === 'wax' && alphaSelect?.name !== 'Clear Wax' ? normalAlpha[paint.name][alphaSelect?.name] : material.partWiseFabric[key].normal,
          // alphaSelect?.type === 'wax' && alphaSelect?.name !== 'Clear Wax'
          material.partWiseFabric[key].normal,
          false
        );
      }
    }
  };

  useEffect(() => {
    if (modelViewer) {
      modelViewer.exposure = alphaSelect?.exposure
      updateMaterial();
      setTimeout(async () => {
        setLoader(false)
        // setModelAtomValue({
        //   ...modelAtomValue,
        //   loading: 100,
        // });
      }, 3000)
    }
  }, [paint, modelAtomValue.src, alphaSelect]);

  // useEffect(() => {
  //   if (modelViewer) {
  //     modelViewer.exposure = inputValue?.Exposure
  //   }
  // }, [inputValue]);

  // if (modelViewer) {
  //   modelViewer.addEventListener("load", (data) => {
  //     const childrenName = document.getElementsByClassName('active-category')?.[0]?.children[0]?.alt
  //     if (paint?.name?.replaceAll('_', ' ') === childrenName) {
  //       // updateMaterial();
  //     }
  //   });
  // }

  document.querySelector("#model-viewer")?.addEventListener("progress", (event) => {
    if (event.detail.totalProgress === 1) {
      setModelAtomValue({
        ...modelAtomValue,
        loading: 100,
      })
      setLoader(false)
    }
  })

  const addToProjectHandler = async () => {
    document.querySelector('model-viewer').fieldOfView = 10
    document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 90%'
    setTimeout(async () => {
      const parts = [
        {
          name: selectedFabric?.name,
          img: GET_URL(selectedFabric?.finish),
          quantity: 1,
          type: "paint",
          selected: selectedFabric,
          code: selectedFabric?.code
        },
        {
          name: alphaSelect?.name,
          img: alphaSelect?.image,
          quantity: 1,
          type: "alpha",
          code: alphaSelect?.code
        },
        {
          name: paint?.handle,
          img: `${Handle_Global_Url}${paint?.image}`,
          quantity: 1,
          type: "handle",
          code: paint?.code
        },
      ]
      const checkSameName = addToProject?.filter(data => data?.name === paint?.name.replaceAll('_', ' '))
      let checkData
      if (checkSameName?.length > 0) {
        checkData = addToProject?.findIndex(data => JSON.stringify(data?.parts) === JSON.stringify(parts))
      } else {
        checkData = -1
      }
      if (checkData === -1) {
        const dataNew = document.querySelector("#model-viewer").toDataURL('image/png')
        const cehckIndex = addToProject?.length ? Number(addToProject?.[0]?.seriesName?.slice(1)) : 0
        const reduceImage = imgload ? await reduceBase64ImageSize(imgload) : ''

        const addData = {
          name: paint?.name.replaceAll('_', ' '),
          type: 'vintage',
          parts,
          quantity: 1,
          base64Image: dataNew,
          alphaImage: reduceImage,
          modelAtomValue,
          fabric: paint,
          alphaSelect,
          seriesName: cehckIndex + 1 > 9 ? `S${cehckIndex + 1}` : `S0${cehckIndex + 1}`
        }
        setAddToProjectImage([dataNew, ...addToProjectImage])
        setAddToProject([addData, ...addToProject])
      } else {
        const updatedData = addToProject?.map((data, index) => {
          if (index === checkData) {
            const partUpdate = data?.parts?.map(partdata => {
              return { ...partdata, quantity: partdata?.quantity + 1 }
            })
            return { ...data, quantity: data.quantity + 1, parts: partUpdate }
          } else {
            return data
          }
        })
        setAddToProject(updatedData)
      }
      document.querySelector("#model-viewer").cameraOrbit = '0deg 75deg 105%'
    }, 1000)
  }

  if (modelAtomValue.src.replace('app', preFix) && preFix) {
    return (
      <>
        <div style={{ position: 'relative' }}>
          {/* {modelAtomValue.loading === 0 && <AntdSpin />} */}
          {loader && <AntdSpin />}
          <div id="card" style={{
            opacity: loader ? '0' : '1',
            // opacity: modelAtomValue.loading === 100 ? '1' : '0',
            transition: 'opacity 1s ease-in-out',

          }}>
            <model-viewer
              tone-mapping="commerce"
              src={modelAtomValue.src.replace('app', preFix)}
              camera-controls
              disable-pan
              // camera-orbit="0deg 90deg 3m"
              style={{ height: "57vh", width: "100%" }}
              id="model-viewer"
            ></model-viewer>
          </div>
        </div>
        <button
          className="btn btn-outline-primary mb-3"
          style={{
            maxWidth: 50,
            width: '100%',
            margin: '0 auto'
          }}
          onClick={() => addToProjectHandler()}
          disabled={loader ? true : false}
        >
          Add To Project
        </button>
      </>
    );
  } else {
    return <></>
  }
};

export default GoogleModelViewer;
